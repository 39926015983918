// 해당 디렉토리에서 실제 수행할 파일
import Vue from 'vue';

const BackLazyLoading = {
    // eslint-disable-next-line no-unused-vars
    inserted(el, binding) {
        let options = {
            //root: document.querySelector(binding.value.rootScrollEl), // scroll event를 감시할 element를 설정 합니다
            threshold: 0.1,
            //rootMargin: "500px 0px"
        };

        // 감시
        el.observer = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                // 관찰 대상이 viewport 안에 들어온 경우 image 로드
                if (entry.isIntersecting) {
                    // data-src 정보를 타켓의 src 속성에 설정
                    entry.target.style.backgroundImage = `url(${entry.target.dataset.src})`;
                    // 이미지를 불러왔다면 타켓 엘리먼트에 대한 관찰을 멈춘다.
                    observer.unobserve(entry.target);
                }
            })
        }, options);

        el.observer.observe(el);
    },
    // eslint-disable-next-line no-unused-vars
    unbind(el, binding) {// 디렉티브 해제 시 옵저버(감시)를 해제
        if(el.observer) {
            el.observer.disconnect();
        }
    },
};
const ImgLazyLoading = {
    // eslint-disable-next-line no-unused-vars
    inserted(el, binding) {
        let options = {
            //root: document.querySelector(binding.value.rootScrollEl), // scroll event를 감시할 element를 설정 합니다
            threshold: 0.1,
            //rootMargin: "500px 0px"
        };

        // 감시
        el.observer = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                // 관찰 대상이 viewport 안에 들어온 경우 image 로드
                if (entry.isIntersecting) {
                    // data-src 정보를 타켓의 src 속성에 설정
                    entry.target.src = entry.target.dataset.src;
                    // 이미지를 불러왔다면 타켓 엘리먼트에 대한 관찰을 멈춘다.
                    observer.unobserve(entry.target);
                }
            })
        }, options);

        el.observer.observe(el);
    },
    // eslint-disable-next-line no-unused-vars
    unbind(el, binding) {// 디렉티브 해제 시 옵저버(감시)를 해제
        if(el.observer) {
            el.observer.disconnect();
        }
    },
};

Vue.directive('background-lazy-loading', BackLazyLoading);
Vue.directive('img-lazy-loading', ImgLazyLoading);