import Vue from 'vue'
import App from './App.vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import router from './config/router.js'
import store from './config/store.js'
import AOS from 'aos'
import 'aos/dist/aos.css'

require('./config/directive/');//lazy-load모듈

Vue.component('font-awesome-icon', FontAwesomeIcon);
library.add(fas);
Vue.config.productionTip = false;

new Vue({
  render: h => h(App),
  mounted() {
    AOS.init();
  },
  router:router,
  store:store,
}).$mount('#app')

