<template>
    <div>
        <div>
            <section v-for="(outerItem, outerIndex) in outerArray" :key="outerIndex">
                <div class="section-box-body" :style="{background:outerItem.background}" :id="outerItem.sectionId">
                    <section-title :sectionTitle="outerItem.sectionTitle"/>
                    <techBox
                            v-for="(innerItem, innerIndex) in outerItem.projectInfo"
                            :key="innerIndex"
                            :leftTitle="innerItem.leftTitle"
                            :contents="innerItem.right.contents"
                            :contentsId="innerItem.right.contentsId"
                            :imgs="innerItem.right.imgs"

                    />
                </div>
            </section>
        </div>
    </div>
</template>
<script>
    import techBox from '../components/TechBox.vue'
    import sectionTitle from '../components/SectionTitle.vue'
    import mixin from "../config/mixin";


    export default {
        name: "WizaiTechnology",
        mixins: [mixin],

        data() {
            return {
                outerArray: [
                    {
                        sectionTitle: "기상・기후・환경 분야",
                        sectionId: "section-tech-1",
                        background: "",
                        projectInfo: [
                            {
                                leftTitle: "기상 예측 및 지구시스템 <br>통합 예측 기술",
                                right: {
                                    contents: '역학/통계/인공지능 모델 기반의 준 실시간 기상자료(AWS, 레이더, 인공위성)의 융합을 통한 예측 자료를 생산합니다. 또한, 다양한 지구시스템을 구현한 서로 다른 수치모델 간 접합으로 전 지구 시스템을 통합 예측합니다.',
                                    contentsId: "contentsId-1",
                                    imgs: [
                                        {img: require('../assets/img/tech/tech0.png'), class: "img_44_9"},
                                        {img: require('../assets/img/tech/tech1.png'), class: "img_49_68"},
                                    ],
                                }
                            },
                            {
                                leftTitle: "기후 예측/<br>기후변화 대응 기술",
                                right: {
                                    contents: "한반도/동아시아/전지구의 기후변화 예측 및 분석 자료를 생산합니다.",
                                    contentsId: "contentsId-2",
                                    imgs: [
                                        {img: require('../assets/img/tech/tech2.png'), class: "img_48"},
                                        {img: require('../assets/img/tech/tech3.png'), class: "img_48"},
                                    ],
                                }
                            },
                            {
                                leftTitle: "수치모델 및 후처리 기술",
                                right: {
                                    contents: "관측 및 모델 자료의 전처리, 자료동화, 품질관리, 후처리 등의 자료처리와 기상/기후/해양/대기확산 등의 모델링 구축 및 자동화를 수행합니다.",
                                    contentsId: "contentsId-3",
                                    imgs: [
                                        {img: require('../assets/img/tech/tech4.png')},
                                    ],
                                }
                            }
                        ]
                    },
                    {
                        sectionTitle: "시스템 구축 분야",
                        sectionId: "section-tech-2",

                        projectInfo: [
                            {
                                leftTitle: "대용량 데이터 <br>수집/저장/분석/제공<br>시스템 구축",
                                right: {
                                    contents: '실시간 대용량 데이터 수집 및 저장, 인터페이스 개발, 빅데이터 시스템 구축 등 공공 및 민간 정보시스템 구축 기술을 보유하고 있습니다.',
                                    contentsId: "contentsId-4",
                                    imgs: [
                                        {img: require('../assets/img/tech/tech6.png'), class: "img_100"}
                                    ],
                                }
                            },
                            {
                                leftTitle: "기후 예측/<br>기후변화 대응 기술",
                                right: {
                                    contents: "한반도/동아시아/전지구의 기후변화 예측 및 분석 자료를 생산합니다.",
                                    contentsId: "contentsId-5",
                                    imgs: [
                                        {img: require('../assets/img/tech/tech7.png'), class: "img_51"},
                                        {img: require('../assets/img/tech/tech8.png'), class: "img_43_5"},
                                        {img: require('../assets/img/tech/tech9.png'), class: "img_36"},
                                        {img: require('../assets/img/tech/tech10.png'), class: "img_34"},
                                        {img: require('../assets/img/tech/tech11.png'), class: "img_25"},

                                    ],
                                }
                            },
                        ]
                    },
                    {
                        sectionTitle: "인공지능 적용 기술",
                        sectionId: "section-tech-3",
                        background: "",
                        projectInfo: [
                            {
                                leftTitle: "데이터 융합 및 <br> 품질관리 기술",
                                right: {
                                    contents: '관측 데이터 학습 및 인공지능 기법 적용을 통해 다양한 관측자료에 대한 이상 데이터를 탐지하며, 인공지능 예측 데이터를 이용한 보정 결과를 제공합니다.',
                                    contentsId: "contentsId-6",
                                    imgs: [
                                        {img: require('../assets/img/tech/tech12.png'), class: "img_80"},

                                    ],
                                }
                            },
                            {
                                leftTitle: "인공지능 기반 기상 예측 기술",
                                right: {
                                    contents: "환경 데이터 특성을 고려한 인공지능 기반 예측 알고리즘을 생성하며, 빅데이터 분석 및 전처리, 분석 데이터 기반 AI 기법 적용 등을 통한 예측 성능을 향상시킵니다.",
                                    contentsId: "contentsId-7",
                                    imgs: [
                                        {img: require('../assets/img/tech/tech13.png'), class: "img_100"},
                                        {img: require('../assets/img/tech/tech14.png'), class: "img_60"},
                                        {img: require('../assets/img/tech/tech15.png'), class: "img_40"},

                                    ],
                                }
                            },
                        ]
                    },
                    {
                        sectionTitle: "빅데이터 융합 기술",
                        sectionId: "section-tech-4",
                        background: "#F5F8FB 0% 0% no-repeat padding-box",
                        projectInfo: [
                            {
                                leftTitle: "기상 ・ 신재생 에너지<br>융합 예측 기술",
                                right: {
                                    contents: '에너지 저감 및 효율적 에너지 관리를 위한 기상・기후 에너지 융합 기술입니다.',
                                    contentsId: "contentsId-9",
                                    imgs: [
                                        {
                                            img: require('../assets/img/tech/tech16.png'),
                                            class: "img_70",
                                            text: "시흥시 정왕동 상세 태양광 예시"
                                        },
                                        {
                                            img: require('../assets/img/tech/tech17.png'),
                                            class: "img_70",
                                            text: "고해상도 태양광 전력 거래량 예측 모델"
                                        },

                                    ],
                                }
                            },
                            {
                                leftTitle: "기상 ・ 헬스/보건<br>융합 예측 기술",
                                right: {
                                    contents: "기상 데이터 및 보건 의료 빅데이터 융합에 따른 헬스케어 기상정보 서비스입니다.",
                                    contentsId: "contentsId-10",
                                    imgs: [
                                        {img: require('../assets/img/tech/tech18_edit.png'), class: "img_70"},
                                    ],
                                }
                            },
                        ]
                    }
                ],
            };
        },

        components: {
            techBox,
            sectionTitle
        },
        mounted() {
            this.scrollToRouteHash()
        },
        methods: {},

    };

</script>

<style scoped>
    @import url("../assets/css/project.css");
</style>