<template>
    <div>
        <div class="question-wrap">
            <div class="question-top-box">
                <content-box
                    :title="topBox.topTitle"
                    :titleTextColor="topBox.titleTextColor"
                    :contents="topBox.topContents"
                    :contentsTextColor="topBox.contentsTextColor"
                    :fontWeight="topBox.fontWeight"
                ></content-box>
            </div>
            <div id="map">

            </div>
        </div>
    </div>
</template>

<script>
import ContentBox from '../components/ContentBox.vue'


export default {
    name: 'WizaiHomepageV2Wizaiquestion',

    data() {
        return {
            topBox:{
                topTitle:"문의하기", 
                topContents: "서울 금천구 가산디지털1로84, 1708호 <br/> nicekooja@wizai.co.kr <br/> 02-6295-0001",
                titleTextColor:this.$store.state.titleColor,
                contentsTextColor:this.$store.state.whiteColor,
                fontWeight:"bold",
            },
        };
    },

    components:{
        ContentBox,
    },

    mounted() {
        const container = document.querySelector("#map");
        const options = {
            center:new kakao.maps.LatLng(37.473386565448926, 126.88543732773876),
            level:3
        };

        const map = new kakao.maps.Map(container,options);

        const marker = new kakao.maps.Marker({
            position:options.center
        });
        const iwContent = '<div class="mapContents">' + 
        '<div class="map-content-title">위즈아이</div>' +
        '<div class="map-content-address">서울 금천구 가산디지털1로84, 1078호</div>'
        '</div>'

        const mapCustomOverlay = new kakao.maps.CustomOverlay({
            position : options.center,
            content: iwContent,
            yAnchor: 1.5,
        });

        // const infowindow = new kakao.maps.InfoWindow({
        //     position : options.center, 
        //     content : iwContent
        // });

        marker.setMap(map);
        mapCustomOverlay.setMap(map);

        window.addEventListener('resize',() => {
            map.setCenter(options.center)
        });
    },

    methods: {
        
    },
};
</script>

<style scoped>
</style>