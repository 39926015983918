<template>
    <div id="content-box-wrap">

        <!-- image 아래 content -->
        <div v-if="type==='content-under-image'" id="content-under-image" :style="{backgroundColor: content.skinColor}">
            <h1 class="main-title">{{content.mainTitle}}</h1>
            <div class="content-box-wrap" v-for="(item,index) in content.sources" :key="index">
                <h2 class="middle-title">{{item.middleTitle}}</h2>
                <div class="content-wrap">
                    <div class="image-wrap" :data-src="item.img" alt="이미지" v-background-lazy-loading></div>
                    <ul class="content">
                        <li v-for="(text, index) in item.content" :key="index">{{text}}</li>
                    </ul>
                </div>
            </div>
        </div>
        <!-- /image 아래 content -->

        <!-- content 아래 image -->
        <div v-else-if="type==='image-under-content'" :style="{backgroundColor: content.skinColor}">

        </div>
        <!-- /content 아래 image -->

        <!-- middleTitle아래 content -->
        <div v-else :style="{backgroundColor: content.skinColor}" id="default">
            <h1 class="main-title">{{content.mainTitle}}</h1>
            <div class="content-box-wrap" v-for="(item,index) in content.sources" :key="index">
                <div class="text-wrap">
                    <h2 class="middle-title">{{item.middleTitle}}</h2>
                    <div class="content-wrap" v-for="(text,index) in item.content" :key="index">{{text}}</div>
                </div>
                <div class="image-wrap">
                    <img v-for="(img,index) in item.img" :data-src="img.src" :alt="img.alt" v-img-lazy-loading :key="index"/>
                </div>
            </div>
        </div>
        <!-- /middleTitle아래 content -->

    </div>
</template>

<script>
export default {
    name: 'WizaiHomepageV2Contenttextbox',

    props:{
        type: {type:String,default:'content-under-title'},
        content:{
            skinColor:{type: String, default:'#FFFFFF'},
            mainTitle:String,
            sources: {
                type: Array,
                default: () => [
                    {
                        middleTitle: [],
                        content: [],
                        img: []
                    }
                ]
            }
        }
    },
    data() {
        return {
            
        };
    },

    mounted() {

    },

    methods: {
        
    },
};
</script>

<style scoped>
@import url('../assets/css/contentBox.css');
</style>