<template>
    <div id="vision-wrap">
        <content-box
                :content="vision"
        ></content-box>
    </div>
</template>

<script>
    import ContentBox from '../components/ContentBox.vue'
    import mixin from "../config/mixin";

    export default {
        name: "WizaiHomepageV2WizaiVision",
        mixins: [mixin],
        components: {ContentBox},
        data(){
            return{
                vision: {
                    skinColor: "",
                    mainTitle: "",
                    sources: [
                        {
                            middleTitle: "기상・기후・환경 분야 예측 기술 혁신",
                            content: ["기상・기후・환경 분야 이론적 지식과 빅데이터, AI, IT 신기술을 접목하여 분야별 기술 고도화와 지속적인 혁신을 통해 고정밀의 정확도 높은 환경 예측정보 제공"],
                            img: [{src: require('@/assets/img/innovation.png'), alt: '혁신'},]
                        },
                        {
                            middleTitle: "통합지구환경예측 솔루션",
                            content: [
                                "서로 다른 모델의 접합과 이기종 시스템 간의 통신을 통해 서로 다른 지구시스템 모델 전체를 통합하여 예측하기 위한 플랫폼",
                                "WFMS(WIZAI Flexible Modeling System)는 3가지 시스템으로 구성되어 분야별 맞춤형 상세 기상/환경 예보가 가능하도록 지원",
                                "1. 관측자료/수치모델 자료 수집 ‧ 분석 빅데이터 시스템",
                                "2. 이기종 수치모델 접합 및 인공지능 모델을 통한 지구시스템 통합 예측 시스템",
                                "3. 수요자가 맞춤형 정보 제공 및 의사결정 지원 시스템"
                            ],
                            img: [{src: require('@/assets/img/solution.png'), alt: '솔루션'},]
                        },
                    ]
                },
            }
        },
        mounted() {
            this.scrollToRouteHash()

            const contentBoxes = document.getElementsByClassName("content-box-wrap")
            Array.from(contentBoxes).forEach((box, index) => {
                const id = `vision-content-${index + 1}`;
                box.setAttribute('id', id);
            });
        },
    }
</script>

<style scoped>
    @import "../assets/css/vision.css";
</style>