<template>
  <div class="sectionTitle-box">
    <div :id="sectionId" class="sectionTitle">
      <div class="sectionCircle"></div>
      {{sectionTitle}}
    </div>
  </div>
</template>

<script>
export default {
  name:"sectionTitle",

  props:{
      sectionTitle:String,
      sectionId:String,
  },

  data() {
      return {
          routerName : this.$route.name,
          display: "none",
      };
  },

  created() {
      
  },

  mounted() {
      
      if(this.routerName == "project"){
          this.display = "flex"
      }
  },

  beforeDestroy(){
      
  },

  methods: {
      
  },
};
</script>

<style scoped>
@import url("../assets/css/sectionTitle.css");

.fade-enter-active, .fade-leave-active {
  transition: opacity 2s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

</style>