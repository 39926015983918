<template>
    <div id="intro-wrap">
        <!-- 회사소개 -->
        <div id="wizai-wrap">
            <content-box
                    :content="intro"
            ></content-box>
        </div>
        <!-- /회사소개 -->

        <!-- 대표이사소개 -->
        <div id="ceo-wrap">
            <h1 class="main-title">대표이사소개</h1>
            <div class="content-box-wrap">
                <div class="color-box">
                    <img :data-src="require('@/assets/img/nicekooja.png')" alt="대표이사" v-img-lazy-loading/>
                    <span>㈜위즈아이 대표이사 구자용</span>
                </div>
                <div class="row-line-wrap">
                    <img :data-src="require('@/assets/img/background/line_wizai.png')" alt="선" v-img-lazy-loading/>
                    <ul class="row-line">
                        <li v-for="(item,index) in ceoIntro" :key="index"><span></span></li>
                    </ul>
                </div>
                <div class="text-wrap">
                    <div v-for="(item,index) in ceoIntro" :key="index">
                        <h1>{{index}}</h1>
                        <div><p v-for="(text,index) in item" :key="index">{{text}}</p></div>
                    </div>
                </div>
            </div>
        </div>
        <!-- /대표이사소개 -->

        <!-- 조직도 -->
        <div id="chart-wrap">
            <h1 class="main-title">조직도</h1>
            <div><img :data-src="chartImg" alt="조직도" v-img-lazy-loading></div>
        </div>
        <!-- /조직도 -->

        <!-- 인증 및 수상 -->
        <div id="award-wrap">
            <h1 class="main-title">인증 및 수상</h1>
            <div class="image-wrap">
                <div v-for="(item, index) in awards" :key="index">
                    <img :data-src="item.src" alt="사진" v-img-lazy-loading>
                    <p>{{item.name}}</p>
                </div>
            </div>
        </div>
        <!-- /인증 및 수상 -->
    </div>
</template>

<script>
    import ContentBox from '../components/ContentBox.vue'
    import mixin from "../config/mixin";

    export default {
        mixins:[mixin],
        name: 'WizaiHomepageV2Wizaiintro',
        props: {
            menuInfo: Object
        },
        components: {
            ContentBox,
        },
        data() {
            return {
                intro: {
                    skinColor: "",
                    mainTitle: "회사소개",
                    sources: [
                        {
                            middleTitle: "",
                            content: [
                                "㈜위즈아이는 기상 ‧ 환경분야 예측 기술 고도화 및 정보화를 위해 관련 분야에서 10년 이상 근무한 전문가들이 모여 2019년에 설립한 회사입니다.",
                                "사명인 WIZAI는 Wizard/Wisdom과 AI의 합성어로 기상 및 환경분야 전문 지식과 인공지능 분야의 기술력을 융합하여 보다 정확하고 정밀한 환경 예측 데이터를 제공하는 것을 목표로 하고 있습니다.",
                                "기후변화 및 환경 재해 ‧ 재난 대응을 위한 예측 기술 및 시스템 개발을 위해 기상 및 환경 관측 ‧ 예측 데이터 분석, 인공지능 기반 기상예측정보 생산, 기상 ‧ 환경 정밀 예측을 위한 통합 예측 솔루션 개발, 정보시스템 구축 및 운영 등 다양한 사업분야에서 성공적인 사례를 제시하고 있습니다."
                            ],
                            img: [{src: require('@/assets/img/wizai_logo.png'), alt: '로고'},]
                        },
                    ]
                },
                ceoIntro: {
                    2023: ["제31회 물관리 심포지엄 및 전시회 물관리 우수상(K-water 사장상)"],
                    2022: ["혁신리더대상 국회의원상 수상"],
                    2020: ["제15회 기상산업대상 기상청장상 수상"],
                    2019: ["위즈아이 설립"],
                    2018: ["한국형수치예보모델개발사업단(KIAPS) 기술자문위원(TAC)", "제 13회 디지털이노베이션 대상(한국일보)"],
                    2004: ["공군 기상단 기상예보장교"],
                    1995: ["서울대학교 지구과학교육 학사, 대기과학 박사수료"],
                },
                chartImg: "",
                awards: [
                    {name: "제15회 기상산업대상 기상청장상", src: require("@/assets/img/awards/award01.png")},
                    {name: "혁신리더대상 국회의원상", src: require("@/assets/img/awards/award02.png")},
                    {name: "제31회 물관리 심포지엄 및 전시회 물관리 우수상(K-water 사장상)", src: require("@/assets/img/awards/award03.png")},
                    {name: "특허 제 10-2097468호", src: require("@/assets/img/awards/patent01.png")},
                    {name: "특허 제 10-2258206호", src: require("@/assets/img/awards/patent02.png")},
                    {name: "특허 제 10-1798405호", src: require("@/assets/img/awards/patent03.png")},
                    {name: "특허 제 10-1798880호", src: require("@/assets/img/awards/patent04.png")},
                    {name: "특허 제 10-1822395호", src: require("@/assets/img/awards/patent05.png")},
                    {name: "특허 제 10-2073528호", src: require("@/assets/img/awards/patent06.png")},
                    {name: "특허 제 10-2118643호", src: require("@/assets/img/awards/patent07.png")},
                    {name: "특허 제 10-2196874호", src: require("@/assets/img/awards/patent08.png")},
                    {name: "특허 제 10-1450524호", src: require("@/assets/img/awards/patent09.png")},
                    {name: "특허 제 10-2590753호", src: require("@/assets/img/awards/patent10.png")},
                ]
            }
        },
        mounted() {
            this.scrollToRouteHash()
            //this.handleScroll(this.$store.state.menuStatus);

            const windowWidth = window.innerWidth;
            const chartImg = windowWidth < 1024 ? require("@/assets/img/organizationChart_mobile.png") : require("@/assets/img/organizationChart_web.png");
            this.chartImg = chartImg;

            // 회사소개 제목 생성
            const textWrap = document.querySelector('.text-wrap');
            const middleTitleWrap = document.createElement('div');
            middleTitleWrap.classList.add('middle-title-wrap');
            middleTitleWrap.innerHTML =
                `<span class="another" style="color:#3471DB;">환경예측</span>
                <span>에 대한</span><br>
                <span class="another" style="color:#E6A360;">혁신</span>
                <span>을 주도하는 선도적 기업</span>`;
            textWrap.insertBefore(middleTitleWrap, textWrap.firstChild);
        }
    };
</script>

<style scoped>
    @import url("../assets/css/wizai.css");
</style>
