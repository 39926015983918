<template>
    <footer>
        <div id="footer-content">
            <div class="link-wrap">
                <p class="title">LINK</p>
                <ul>
                    <li v-for="(link, index) in menuInfo" :key="index">
                        <router-link :to="link.path" @click.native="subMenuOpen(link.path)">{{link.name}}</router-link>
                    </li>
                </ul>
            </div>
            <div class="about-wrap">
                <p class="title">ABOUT US</p>
                <P class="content">대표전화 : 02-6295-0001</P>
                <P class="content">팩스 : 02-6295-0004</P>
                <P class="content">이메일 : wizai@wizai.co.kr</P>
            </div>
        </div>
        <hr>
        <div id="footer-btm">
            <router-link to="/home" @click.native="subMenuOpen('/home')" active-class="active">
                <img :data-src="require('@/assets/img/logo/wizai_con.png')" alt="logo" v-img-lazy-loading>
            </router-link>
            <div class="copyright">
                <p class="content">서울시 금천구 가산디지털1로 84 에이스하이엔드타워8차 1707~1708호</p>
                <p class="content">Copyright @ 2023 WIZAI. All Rights reserved.</p>
            </div>
        </div>
    </footer>
</template>

<script>
    import {mapState} from "vuex";
    import mixin from "../config/mixin";

    export default {
        name: 'WizaiHomepageV2Footer',
        mixins: [mixin],
        computed: {
            ...mapState({menuInfo: state => state.menuInfo})
        },
        data() {
            return {};
        },
        methods: {
            /*
                기능: 웹 부메뉴 열기
                설명: menuStatus변수에 클릭이벤트로 전달된 주메뉴 이름을 저장
                     해당 값 전제로 하여 menuStatus에 해당하는 부메뉴가 출력되도록 <ul> if문에 전달
             */
            subMenuOpen(linkPath) {
                console.log(linkPath)
                this.checkMenuByRoute(this.menuInfo, linkPath)
            },
        }
    };
</script>

<style scoped>
    @import url("../assets/css/footer.css");
</style>