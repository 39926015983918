<template>
  <div>
    <Header></Header>
    <section>
      <transition name="fade" mode="out-in">
        <router-view></router-view>
      </transition>
    </section>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "./components/Header.vue"
import Footer from './components/Footer.vue'

export default {
  name: 'WizaiHomepageV2App',

  components:{
    Header,
    Footer
  },

  data() {
    return {
    };
  },
  mounted() {
  },
  methods: {
    
  },
};
</script>

<style>
  @import url("./assets/font/font.css");
  @import url("./assets/css/main.css");
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }

  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
</style>