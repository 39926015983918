<!--
    https://github.com/iampava/imagemin-webp-webpack-plugin/issues/56
    imagemin-webp-webpack-plugin빌드 단계에 따른 webp이미지 참조 불가이슈.
    문제 해결시 webp으로 변경 예정
-->

<template>
    <picture>
        <source :srcset="webpSrc" type="image/webp" />
        <img :src="pngSrc" alt="Fast red car" />
    </picture>
</template>

<script>
    export default {
        name: "ImgOptimization",
        props: ['ImgSrc'],
        computed: {
            webpSrc() {
                return require(`@/assets/img/${this.ImgSrc}.webp`);
            },
            pngSrc() {
                return require(`@/assets/img/${this.ImgSrc}.png`);
            },
        },
    }
</script>

<style scoped>

</style>