/*
import Vue from 'vue';
import VueLazyload from 'vue-lazyload';
*/

export default{
    data() {
        return {
            currentScrollPosition: 0,
        };
    },
    methods: {
        /*
            기능: 타페이지 요소로의 스크롤
            설명: 다른 페이지(.vue)간의 url전달로 인한 스크롤 이동 로직
                  렌더링이 완료되기 전 스크롤 이동을 방지하기 위해 3초의 딜레이 시간을 적용함
         */
        scrollToRouteHash(){
            setTimeout(() => {
                const hash = this.$route.hash;
                if (hash && hash !== '#') {
                    const element = document.querySelector(hash);
                    if (element) {
                        window.scrollTo({
                            top: element.offsetTop,
                            behavior: 'smooth'
                        });
                    }
                }
            }, 300);
        },

        /*
            기능: route경로를 활용하여 어떤 메뉴에 해당하는지 확인하기
            인자: menu정보 Object($store에 전역변수화)
            반환: 해당하는 menu이름(대문자)
            설명: 해당 메소드가 사용되는 route경로를 통해 menuInfo의 path정보와 같다면
                  그 순서의 menu name을 반환한다.
        */
        checkMenuByRoute(menuInfo,routePath){
            let path=routePath
            let menu={background:"",title:"",path:"", name:"",sub:""}
            for(let i in menuInfo){
                if(path===menuInfo[i].path){
                    menu.background=menuInfo[i].background
                    menu.title=menuInfo[i].title
                    menu.path=menuInfo[i].path
                    menu.name=menuInfo[i].name
                    // eslint-disable-next-line no-prototype-builtins
                    if(menuInfo[i].hasOwnProperty('sub')){
                        menu.sub=menuInfo[i].sub
                    }
                    break;
                }else{
                    menu = {background:"",title:"",path:"", name:"",sub:"false"}
                }
            }
            this.$store.commit("setMenuStatus", menu);
            return menu
        },
        /*
            기능: 스크롤 발생시, 엘레먼트로 스크롤 부드럽게 이동
            인자:
            설명:
        handleScroll(currentIndex) {
            const scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
            //console.log(scrollPosition)
            if (scrollPosition > this.currentScrollPosition) {
                //console.log('down')
                // scroll down
                const subItems = Object.values(this.$store.state.menuInfo[currentIndex].sub);
                const nextIndex = subItems.findIndex(item => item.path === this.$route.hash) + 1;
                if (nextIndex < subItems.length) {
                    this.$router.push(subItems[nextIndex].path);
                }
            } else if (scrollPosition < this.currentScrollPosition) {
                if(scrollPosition <= 500.25){
                    return;
                }
                //console.log('up')
                // scroll up
                const subItems = Object.values(this.$store.state.menuInfo[currentIndex].sub);
                const prevIndex = subItems.findIndex(item => item.path === this.$route.hash) - 1;
                if (prevIndex >= 0) {
                    this.$router.push(subItems[prevIndex].path);
                } else if (prevIndex === -1) {
                    this.$router.push(Object.values(this.$store.state.menuInfo[currentIndex].sub)[0].path);
                }
            }
            this.currentScrollPosition = scrollPosition;
        },
        */
    }
}
