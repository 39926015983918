<template>
    <div id="project-wrap">
        <content-box
                :type="'content-under-image'"
                :content="projcetInfo"
        ></content-box>

        <div id="partners-box">
            <div class="contents-box">
                <h1 class="main-title">고객사</h1>
                <div class="content-wrap">
                    <div class="each-img" v-for="(item, index) in partners" :key="index">
                        <img :data-src="item.src" :alt="item.name" v-img-lazy-loading/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import contentBox from '../components/ContentBox.vue'
    import mixin from "../config/mixin";

    export default {
        name: 'WizaiHomepageWizaiproject',
        mixins: [mixin],
        data() {
            return {
                projcetInfo: {
                    mainTitle: "주요사례",
                    sources: [
                        {
                            middleTitle: "기상 및 환경 예측 사업",
                            content: [
                                "가까운 미래 예측시스템 구축을 위한 기반 기술 개발",
                                "여름철 대류성 호우 발생의 강도 및 구역 예측 정량화 기술",
                                "해양수치모델링과 지능정보기술을 활용한 해양예측 정확도 향상 연구",
                                "해양기상예측시스템 운영",
                                "관측자료, 분석자료, 수치모델 실험을 이용한 한반도 집중호우 및 특이기상사례 분석",
                                "기상관측장비 연구 실험장비 운영 및 활용전략 수립 연구",
                                "컴퓨터 비전 기법을 이용한 관측자료 융합 기술 개발",
                                "서비스 플랫폼 기반 미세먼지 예보 정확도 향상 문제 해결 기술 개발",
                                "황사 연무 통합 예측 모델 전처리 및 검증 기술 개발",
                                "기상기후변화를 반영한 황사예측모델 개발",
                                "공군 기후변화 시나리오 생산 연구 용역",
                                "위성영상 및 기상관측 데이터를 활용한 작전지역 안개탐지 및 예측서비스",
                            ],
                            img: require('@/assets/img/background/project_weather.png'),
                        },
                        {
                            middleTitle: "인공지능 기반 사업",
                            content: [
                                "IoT, AI 등 기반 도시 상세 스마트 태양광 에너지 및 생활건강 기상정보 생산 기술 개발",
                                "반도체 부품 생산 라인 공정 수율을 위한 인공지능 기반 환경계측 스마트센서 개발",
                                "위성영상 및 기상관측 데이터를 활용한 작전지역 안개탐지 및 예측서비스",
                                "DNA기반 화학사고 예측 및 리스크 평가 기술 개발",
                                "인공지능 기반 시민 체감형 강수예보 서비스 체계 개발",
                                "컴퓨터 비전 기법을 이용한 관측자료 융합 기술 개발",
                                "데이터 융합 활용을 위한 데이터 컬렉션 구축",
                                "주요 화훼류 품질 데이터",
                                "헬스케어를 위한 실내정원 식물의 활용 증진 플랫폼 구축",
                            ],
                            img: require('@/assets/img/background/project_ai.png'),
                        },
                        {
                            middleTitle: "정보시스템 구축 사업",
                            content: [
                                "수문 기상 가뭄정보 시스템 구축 및 운영",
                                "WMO 장기예보 선도센터 시스템 구축 및 운영",
                                "위성정보 스마트 서비스 기술 개발",
                                "스마트 항공 예보시스템 개선 구축 및 운영",
                                "위성영상 활용 알고리즘 및 부가 산출물 검증용 지상 관측 자료처리 시스템 구축",
                                "맞춤형 기상기후 빅데이터 서비스 기반 구축",
                                "서비스 플랫폼 기반 미세먼지 예보정확도 향상 문제 해결",
                            ],
                            img: require('@/assets/img/background/project_system.png'),
                        },
                        {
                            middleTitle: "연구개발 사업",
                            content: [
                                "해양 수치모델링과 지능정보기술을 활용한 해양예측 정확도 향상 연구",
                                "관측자료, 분석 자료, 수치모델 실험을 이용한 한반도 집중호우 및 특이기상 사례 분석",
                                "기상관측 장비 연구 실험 장비 운영 및 활용전략 수립 연구",
                            ],
                            img: require('@/assets/img/background/project_dev.png'),
                        },
                    ]
                },
                partners: [
                    {name: "기상청", src: require('@/assets/img/logo/kma_logo.png')},
                    {name: "항공기상청", src: require('@/assets/img/logo/amo_logo_edit.png')},
                    {name: "국가기상위성센터", src: require('@/assets/img/logo/nmsc_logo.png')},
                    {name: "국립기상과학원", src: require('@/assets/img/logo/nims_logo.png')},
                    {name: "해양수산과학기술진흥원", src: require('@/assets/img/logo/kimstp_logo.png')},
                    {name: "한국기상산업기술원", src: require('@/assets/img/logo/kmi_logo.png')},
                    {name: "한국지능정보사회진흥원", src: require('@/assets/img/logo/nia_logo.png')},
                    {name: "중소기업기술정보진흥원", src: require('@/assets/img/logo/tipa_logo.png')},
                    {name: "한국과학기술정보연구원", src: require('@/assets/img/logo/kisti_logo.png')},
                    {name: "KIAPS", src: require('@/assets/img/logo/kiaps_logo.png')},
                    {name: "대우조선해양", src: require('@/assets/img/logo/dsme_logo.png')},
                    {name: "한국항공우주연구원", src: require('@/assets/img/logo/kari_logo.png')},
                    {name: "한국환경산업기술원", src: require('@/assets/img/logo/keiti_logo.png')},
                    {name: "대한민국공군", src: require('@/assets/img/logo/kaf_logo.png')},
                    {name: "정보통신산업진흥원", src: require('@/assets/img/logo/nipa_logo.png')},
                ]
            };
        },

        components: {
            contentBox
        },
        mounted() {
            this.scrollToRouteHash()
        },
        methods: {},
    };
</script>

<style scoped>
    @import url("../assets/css/project.css");
</style>