<template>
    <div id="header-wrap">
        <header>
            <div class="header-wrap">
                <div id="color-wrap">
                    <div  class="header-main">
                        <router-link to="/home" active-class="backgroundTransparent" @click.native="subMenuOpen('HOME')">
                            <img id="logo" :src="require('@/assets/img/logo/wizai_con.png')" alt="logo">
                        </router-link>
                        <div class="btn-group">
                            <transition name="fade">
                                <div class="menu-btn" v-if="menuStatusClose" @click="menuOpen">
                                    <font-awesome-icon icon="bars"></font-awesome-icon>
                                </div>
                            </transition>
                        </div>
                        <!-- pc 메뉴 -->
                        <div class="menus">
                            <router-link v-for="link in menuInfo" @click.native="subMenuOpen(link.path)" active-class="active-menu" :id="link.name" :key="link.name.toString()" :to="link.path">
                                {{link.name}}
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="header-sub">
                    <div class="sub-menus" v-if="menuStatus.sub!=='false'">
                        <!-- eslint-disable vue/no-use-v-if-with-v-for,vue/no-confusing-v-for-v-if -->
                        <ul v-for="link in menuInfo" v-if="link.hasOwnProperty('sub') && menuStatus.name===link.name" :key="link.name.toString()">
                            <li v-for="sublink in link.sub" :key="sublink.name.toString()">
                                <router-link :to="sublink.path" active-class="active-sub">{{sublink.name}}</router-link>
                            </li>
                        </ul>
                    </div>
                </div>
                <!-- 모바일 메뉴 -->
                <transition name="fade">
                    <div class="mobile-menu-box" v-if="menuStatusOpen">
                        <div class="x-btn" @click="menuClose">
                            <font-awesome-icon icon="times"></font-awesome-icon>
                        </div>
                        <div class="menus">
                            <div class="accordion-item" v-for="link in menuInfo" :key="'mob'+link.name.toString()" v-bind:class="{ 'accordion-active': link.mobileSubActive }" :id="'mob'+link.name">
                                <div class="accordion-header" @click="mobileSubMenuToggle(link)" :class="link.name">
                                    <router-link :key="'mob'+link.name.toString()" :to="link.path" @click.native="mobileSubMenuClick(link.path)">
                                        {{link.name}}
                                    </router-link>
                                    <div class="accordion-header-div">
                                        <div class="accordion-caret"></div>
                                    </div>
                                </div>
                                <div class="accordion-body" v-if="link.hasOwnProperty('sub')">
                                    <router-link v-for="(sublink) in link.sub" :key="'mob'+sublink.name.toString()" :to="link.path+sublink.path" @click.native="mobileSubMenuClick(link.path)">
                                        {{sublink.name}}
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </transition>
            </div>
        </header>
        <title-box v-if="this.menuStatus.name!==''" :menu="this.menuStatus" />
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    import mixin from '../config/mixin'
    import TitleBox from "./TitleBox";

    export default {
        name: 'WizaiHomepageV2Header',
        components: {TitleBox},
        data() {
            return {
                menuStatusClose:this.$store.state.menuStatusClose,
                menuStatusOpen:this.$store.state.menuStatusOpen,
            };
        },
        mixins: [mixin],
        computed:{
            ...mapState({menuInfo: state => state.menuInfo}),
            ...mapState(['menuStatus']),
        },
        watch:{
            $route () {
                let path=this.$route.path
                this.checkMenuByRoute(this.menuInfo,path)
            }
        },
        mounted() {
            window.addEventListener("resize",this.menuClose)
            let path=this.$route.path
            let hash=this.$route.hash
            this.checkMenuByRoute(this.menuInfo,path)
            if(hash!==''){
                this.$router.push(path)
            }
        },
        methods: {
            /*
                기능: 모바일메뉴 열기 & 닫기
                설명: 메뉴여닫기 여부에 해당하는 변수, 값변경
             */
            menuOpen(){
                this.menuStatusClose = false;
                this.menuStatusOpen = true;
            },
            menuClose(){
                this.menuStatusClose = true;
                this.menuStatusOpen = false;
            },

            /*
                기능: 웹 부메뉴 열기
                설명: menuStatus변수에 클릭이벤트로 전달된 주메뉴 이름을 저장
                     해당 값 전제로 하여 menuStatus에 해당하는 부메뉴가 출력되도록 <ul> if문에 전달
             */
            subMenuOpen(linkPath){
                this.checkMenuByRoute(this.menuInfo,linkPath)
            },

            /*
                기능: 모바일 부메뉴 활성상태 토글
                input: 주메뉴객체(menuInfo의 home, vision, technology, project, community)
                설명: 아코디언 스타일로 적용되었으며, menuInfo객체의 mobileSubActive변수 상태를 기준으로
                     클릭이벤트를 통해 인자로 전달된 주메뉴객체(link)를 활성화하고, 그를 제외한 주메뉴는 비활성화처리한다.
             */
            mobileSubMenuToggle(link){
                if(!link.mobileSubActive){
                    for(let i in this.menuInfo){
                        if(link!==this.menuInfo[i]){
                            this.$set(this.menuInfo[i], 'mobileSubActive', false);
                        }
                    }
                }
                this.$set(link, 'mobileSubActive', !link.mobileSubActive);
            },

            /*
                기능: 모바일 서브메뉴 선택시 동작 정의
                input: 서브메뉴의 name(변수값에 정의되어있음)
                설명: 모바일 서브메뉴 클릭시 두개의 함수를 동시호출 할 목적으로 하나의 함수로 통합한다.
             */
            mobileSubMenuClick(linkName){
                this.menuClose()
                this.subMenuOpen(linkName)
            }
        },
    };
</script>

<style scoped>
    @import url("../assets/css/header.css");
    @import url('../assets/font/font.css');
</style>