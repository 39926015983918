import VueRouter from 'vue-router'
import Vue from 'vue'
import Home from '../views/Home'
import Wizai from '../views/Wizai'
import Vision from "../views/Vision";
import Technology from "../views/Technology";
import Project from '../views/Project'
import Community from '../views/Community'

Vue.use(VueRouter);

const router = new VueRouter({
    // eslint-disable-next-line no-unused-vars
    scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
            return {
                selector: to.hash,
                behavior: 'smooth',
            };
        } else {
            return { x: 0, y: 0 };
        }
    },
    routes:[
        {
            path:"/",
            redirect:"/home"
        },
        {
            path:"/home",
            component:Home,
            name:"home",
        },
        {
            path:"/intro",
            component:Wizai,
            name:"intro",
        },
        {
            path:"/vision",
            component:Vision,
            name:"vision",
        },
        {
            path:"/technology",
            component:Technology,
            name:"technology",
        },
        {
            path:"/project",
            component:Project,
            name:"project",
        },
        {
            path:"/community",
            component:Community,
            name:"community",
        },
    ],

});

export default router;