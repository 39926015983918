<template>
    <transition id="fade-in">
        <div id="title-box-wrap" :style="{ backgroundImage: 'url(' + menu.background+')' }">
            <h1 class="title">{{menu.title}}</h1>
            <div class="title-menu-wrap">
                <router-link to="/" class="home-menu">
                    <img src="../assets/img/icon/home_con.png" alt="home"/>
                </router-link>
                <div class="division-line">|</div>
                <div class="main-menu">
                    {{menu.name}}
                    <div class="polygon-down"><img src="../assets/img/icon/Polygon.png" alt="삼각형"></div>
                </div>
                <div class="division-line">|</div>
                <div class="sub-menu">
                    {{menu.title}}
                    <div class="polygon-down"><img src="../assets/img/icon/Polygon.png" alt="삼각형"></div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
    export default {
        name: "TitleBox",
        props: {
            menu: Object
        },
        data(){
            return{
                menuStatus:this.$store.state.menuStatus,
                menuStatusClose:this.$store.state.menuStatusClose,
                menuStatusOpen:this.$store.state.menuStatusOpen,
            }
        },
        mounted() {
            this.menuStatus=this.$store.state.menuStatus
        },
    }
</script>

<style scoped>
    @import "../assets/css/titleBox.css";
</style>