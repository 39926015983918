import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const store = new Vuex.Store({
    state:{
        titleColor:"#06234f",
        menuStatusClose:true,
        menuStatusOpen:false,
        whiteColor:"#ffffff",
        menuStatus: {background:"",title:"",path:"", name:"",sub:""},
        menuInfo:{
            wizai: {
                background:require('@/assets/img/background/title_wizai.png'),
                title:"회사소개",
                path:"/intro",
                name: "WIZAI",
                sub:{
                    intro: {path:"#wizai-wrap",name:"회사 소개"},
                    introCEO: {path:"#ceo-wrap",name:"대표 소개"},
                    organization: {path:"#chart-wrap",name:"조직도"},
                    awards: {path:"#award-wrap",name:"인증 및 수상"},
                },
                mobileSubActive: false,
            },
            vision: {
                background:require('@/assets/img/background/title_vision.png'),
                title:"비전",
                path:"/vision",
                name: "VISION",
                sub:{
                    innovation:{path:"#vision-content-1", name:"기상·기후·환경 분야 예측 기술 혁신"},
                    solution:{path:"#vision-content-2", name:"통합지구환경예측 솔루션"},
                }
            },
            technology: {
                background:require('@/assets/img/background/title_technology.png'),
                title:"기술부문",
                path:"/technology",
                name: "TECHNOLOGY",
                sub: {
                    meteoEnviron: {path:"#section-tech-1",name:"기상·환경 분야"},
                    system: {path:"#section-tech-2",name:"시스템 구축 분야"},
                    ai: {path:"#section-tech-3",name:"인공지능 분야"},
                    bigdata: {path:"#section-tech-4",name:"빅데이터 융합 기술"},
                },
                mobileSub: false,
            },
            project: {
                background:require('@/assets/img/background/title_project.png'),
                title:"프로젝트",
                path:"/project",
                name: "PROJECT",
                sub: {
                    project: {path:"#content-under-image",name:"주요사례"},
                    client: {path:"#partners-box",name:"고객사"},
                },
                mobileSub: false,
            },
            /*
            community: {
                background:require('@/assets/img/background/title_community.png'),
                title:"커뮤니티",
                path:"/notice",
                name:"COMMUNITY",
                sub: {
                    notice: {path: "/notice", name: "알림/홍보"},
                    forum: {path: "/forum", name: "포럼"},
                    reference: {path: "/reference", name: "자료실"},
                },
                mobileSub: false,
            }
            */
        },
    },
    mutations: {
        setMenuStatus(state, value) {
            state.menuStatus = value;
        }
    }
})

export default store;