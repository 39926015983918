<template>
    <div id="home-wrap">
        <!-- 영상화면 -->
        <div class="video-section">
            <div class="video-box">
                <swiper :options=this.swiperComponentOptions class="slider-container">
                    <swiper-slide v-for="(item, index) in videos" :key="index">
                        <video :src="item.src" muted autoplay loop></video>
                    </swiper-slide>
                    <div class="swiper-pagination" slot="pagination"></div>
                </swiper>
            </div>
            <div class="text-box">
                <p class="title">환경예측에 대한<br> 혁신을 주도하는 선도적 기업</p>
                <p class="content">전통적 방식에서 벗어나<br> 신기술을 접목한 새로운 기상·기후예측 방법론 제시를 통해<br> 급변하는 미래 기후 위기 대응에 앞장서겠습니다.</p>
            </div>
        </div>
        <!-- /영상화면 -->

        <!-- Our Technology 부분 -->
        <div class="technology-box">
            <div class="contents-box">
                <div>
                    <p class="title">Our Technology</p>
                    <p class="content">기상 및 환경분야 전문 지식과 인공지능 분야의 기술력을 융합하여 보다 정확하고 정밀한 환경 예측 데이터를 제공하고 있습니다.</p>
                </div>
                <div class="image-wrap" :style="{backgroundImage: 'url('+this.technology.backgroundImage+')'}">
                    <div v-for="(item, index) in technology.tap"
                         :key="index"
                         :class="[index, {'active': item.mouseoverCheck }]"
                         @mouseenter="mouseoverCheckOfTech(item,index)"
                         @mouseleave="mouseoverCheckOfTech(item,index)"
                         @click="$router.push({path:'/technology', hash:item.hash})"
                    >
                        <div class="arrow-point"><img src="../assets/img/icon/arrow.png" alt="화살표"/></div>
                        <div>
                            <p class="icon"><img :data-src="item.con" :alt="item.name" v-img-lazy-loading/></p>
                            <p class="text">{{item.name}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- /Our Technology 부분 -->

        <!-- WFMS 부분 -->
        <div class="vision-box" :data-src="require('@/assets/img/background/wfms.png')" v-background-lazy-loading>
            <div class="contents-box">
                <div class="title-wrap">
                    <p class="title">WFMS</p>
                    <p class="content">우리는 서로 다른 모델의 접합과 이기종 시스템간의 통신을 통해 서로 다른 지구시스템 모델 전체를 통합하여 예측하고자, 통합지구환경예측시스템인 WFMS를 구축하였습니다.</p>
                </div>
                <div class="content-wrap">
                    <div v-for="(item, index) in wfms" :key="index" :class="index">
                        <div> <p>{{item.content}}</p> </div>
                        <div class="arrow-point"><img :data-src="item.src" alt="사진" v-img-lazy-loading/></div>
                    </div>
                </div>
            </div>
        </div>
        <!-- /WFMS 부분 -->

        <!-- Community 부분 -->
<!--    서버(DB)연동해야함
        <div class="community-box">
            <div class="contents-box">
                <p class="title">COMMUNITY</p>
                <div class="content-wrap"></div>
            </div>
        </div>
-->
        <!-- /Community 부분 -->

        <!-- Our Partners 부분 -->
        <div class="partners-box">
            <div class="contents-box">
                <p class="title">Our Partners</p>
                <div class="content-wrap">
                    <div class="each-img" v-for="(item, index) in partners" :key="index" >
                        <img :data-src="item.src" :alt="item.name" v-img-lazy-loading>
                    </div>
                </div>
            </div>
        </div>
        <!-- /Our Partners 부분 -->
    </div>
</template>

<script>
    import ImgOptimization from "../components/ImgOptimization";
    import Vue from 'vue'
    import VueAwesomeSwiper from 'vue-awesome-swiper'
    import 'swiper/dist/css/swiper.css'
    Vue.use(VueAwesomeSwiper, /* { default global options } */)

export default {
    name: 'WizaiHomepageV2Wizaihome',
    components: { ImgOptimization },
    data() {
        return {
            swiperComponentOptions: {
                loop: true,
                autoplay:{
                    delay:5000,
                },
                slidesPerView: 1,
                spaceBetween: 0,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                },
                centeredSlides: true,
            },
            videos: [
                {src: require('@/assets/video/weather.mp4')},
                {src: require('@/assets/video/ai.mp4')},
            ],
            overCheck:String,
            technology: {
                backgroundImage: require('@/assets/img/background/weather.png'),
                tap:{
                    weather: {name:"기상·기후·환경",con:require('@/assets/img/icon/weather_con.png'),hash:"#section-tech-1",mouseoverCheck: false},
                    system: {name:"시스템 구축",con:require('@/assets/img/icon/system_con.png'),hash:"#section-tech-2",mouseoverCheck: false},
                    ai: {name:"인공지능 적용 기술",con:require('@/assets/img/icon/ai_con.png'),hash:"#section-tech-3",mouseoverCheck: false},
                    bigdata: {name:"빅데이터 융합 기술",con:require('@/assets/img/icon/bigdata_con.png'),hash:"#section-tech-4",mouseoverCheck: false},
                }
            },
            wfms: {
                bigdata: {content:"지구환경예측을 위한 각종 관측자료 수집과 분야별 수치모델자료를 수집 ‧ 분석하는 빅데이터 시스템",src:require('@/assets/img/icon/wfms01_con.png')},
                system: {content:"이기종 수치모델 접합, 인공지능 모델 등을 통해 지구시스템 전체를 예측하는 통합 예측 시스템", src:require('@/assets/img/icon/wfms02_con.png')},
                weather: {content:"최종적으로 수요자가 원하는 다양한 방식을 통해 분석 및 의사결정을 지원하는 정보시스템 으로 구성되어 분야별 맞춤형 상세 기상/환경예보가 가능하도록 지원합니다.", src:require('@/assets/img/icon/wfms03_con.png')},
            },
            partners: [
                {name:"기상청", src:require('@/assets/img/logo/kma_logo.png')},
                {name:"항공기상청", src:require('@/assets/img/logo/amo_logo_edit.png')},
                {name:"국가기상위성센터", src:require('@/assets/img/logo/nmsc_logo.png')},
                {name:"국립기상과학원", src:require('@/assets/img/logo/nims_logo.png')},
                {name:"해양수산과학기술진흥원", src:require('@/assets/img/logo/kimstp_logo.png')},
                {name:"한국기상산업기술원", src:require('@/assets/img/logo/kmi_logo.png')},
                {name:"한국지능정보사회진흥원", src:require('@/assets/img/logo/nia_logo.png')},
                {name:"중소기업기술정보진흥원", src:require('@/assets/img/logo/tipa_logo.png')},
                {name:"한국과학기술정보연구원", src:require('@/assets/img/logo/kisti_logo.png')},
                {name:"KIAPS", src:require('@/assets/img/logo/kiaps_logo.png')},
                {name:"대우조선해양", src:require('@/assets/img/logo/dsme_logo.png')},
                {name:"한국항공우주연구원", src:require('@/assets/img/logo/kari_logo.png')},
                {name:"한국환경산업기술원", src:require('@/assets/img/logo/keiti_logo.png')},
                {name:"대한민국공군", src:require('@/assets/img/logo/kaf_logo.png')},
                {name:"정보통신산업진흥원", src:require('@/assets/img/logo/nipa_logo.png')},
            ]
        };
    },
    methods: {
        /*
            기능:
            인자: this.technology.tap의 index(weather,ai...)와 item(name,con,hash...)
            설명: mouseEvent(enter, leave)에 따라 발생되는 함수이다.
                 this.technology.tap.타겟.mouseoverCheck의 값변경을 통해 active클래스를 추가한다.
                 this.technology.backgroundImage를 타겟의 정보와 매칭되는 값으로 변경한다.(비동기적 이미지 변경)
        */
        mouseoverCheckOfTech(item,index){
            item.mouseoverCheck=!item.mouseoverCheck
            setTimeout(() => {
                this.technology.backgroundImage = require('@/assets/img/background/' + index + '.png');
            },10);
        }

    },

};
</script>

<style scoped>
@import url("../assets/css/home.css");
</style>