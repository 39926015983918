<template>
    
        <div class="section-box">
              <div class="section-itembox" :style="{background:background}">
                  <div class="section-itembox-left-tech" v-html="leftTitle"></div>
                  <div class="section-itembox-right-tech">
                    <div class="section-itembox-right-contents-tech" v-html="contents"></div>
                    <div class="section-itembox-right-imgs-tech">
                      <div id="section-itembox-right-imgs-tech-div" v-for="(data,index) in imgs"
                      :key="index" :class="data.class">
                          <div class="section-itembox-right-imgs-text-tech">{{data.text}}</div>
                          <img class="" style="width:100%;" 
                          :data-src="data.img" v-img-lazy-loading
                          >
                      </div>
                        </div>    

                    

                </div>
              </div>    
        </div>
      
</template>

<script>
export default {


  props:{
      contentsId:String,
      background:String,
      titleFontColor:String,
      headerTitle:String,
      leftTitle:String,
      contentsFontColor:String,
      imgs:Array,
      contents:String,
  },

  data() {
      return {
          routerName : this.$route.name,
          display: "none",
      };
  },

  created() {
      
  },

  mounted() {
      
      if(this.routerName == "project"){
          this.display = "flex"
      }
  },

  beforeDestroy(){
      
  },

  methods: {
      
  },
};
</script>

<style scoped>
@import url("../assets/css/TechBox.css");

.fade-enter-active, .fade-leave-active {
  transition: opacity 2s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
  

</style>